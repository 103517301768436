// this is also in App\Model\Company\CompanyTypes::toUser()
export const companyTypes = {
    INSURER: 'Insurer',
    MGA: 'Managing General Agent',
    BROKER: 'Broker',
    ADJUSTING_FIRM: 'Adjusting Firm',
    CONTRACTOR: 'Contractor',
    WASTE_MANAGEMENT: 'Waste Management',
    LANDFILL: 'Landfill',
    STAKEHOLDER: 'Stakeholder',
};

export const companyTypesOrdered = [
    'INSURER',
    'MGA',
    'BROKER',
    'ADJUSTING_FIRM',
    'CONTRACTOR',
    'WASTE_MANAGEMENT',
    'LANDFILL',
    'STAKEHOLDER',
];

export const companyTypesOrderedWithoutWaste = companyTypesOrdered.filter(
    (ct) => !['WASTE_MANAGEMENT', 'LANDFILL'].includes(ct)
);
